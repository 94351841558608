import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  getAuth,
  signInWithPopup,
  GithubAuthProvider,
  signInWithCustomToken,
  signInWithCredential
} from 'firebase/auth'
import _ from 'lodash';


var mainBaseUrl =  function() {
  var url = window.location.href
  if(url.includes("http://localhost")) return `http://localhost:3000`
  else return `https://hx3cymrjm2.us-east-1.awsapprunner.com`
}


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCthsqsb5i_YevgwyUpgI7V4RxrxzBZMlw',
  authDomain: 'devdocs-48872.firebaseapp.com',
  projectId: 'devdocs-48872',
  storageBucket: 'devdocs-48872.appspot.com',
  messagingSenderId: '836898546353',
  appId: '1:836898546353:web:31d48c155978930db93890',
  measurementId: 'G-KV13PWHGXR',
}

var parseJwt = function (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

var checkAuth = async function (token, url) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${token}`)
  var raw = JSON.stringify({
    idToken: token,
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }
  var response = await fetch(
    `${url}/introspect`,
    requestOptions,
  )
  var resJson = await response.json()

  if (resJson?.users) {
    var users = resJson?.users

    return users[0]
  } else {
    return 'unauthenticated'
  }
}

var refreshToken = async function(options) {
  const {refreshToken, url} = options
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    refresh_token: refreshToken
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  var response = await fetch(
    `${url}/refresh`,
    requestOptions,
  )
  var resJson = await response.json()

  return resJson
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
var authInstance = function () {
  var provider = new GithubAuthProvider()
  ;(this.setAuth = async function () {
    var url = await this.getUrl()
    const globalObect = this
    try {
      var result = await signInWithPopup(auth, provider)

      await auth.currentUser.delete()
      const credential = GithubAuthProvider.credentialFromResult(result)
      const token = credential.accessToken
      await this.postToAuthn(token)
      await this.postToAuthn(token)

      // The signed-in user info.
      this.auth = result.user
    } catch (error) {
      const email = error.customData.email
      // The AuthCredential type that was used.
      const credential = GithubAuthProvider.credentialFromError(error)
      const { accessToken } = credential
      var myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${accessToken}`)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({ client: 'webapp' }),
      }

      var tokenResponse = await fetch(
        `${url}/company/docstar/authenticate`,
        requestOptions,
      )
      var tokenJson = await tokenResponse.json()

      this.token = tokenJson?.token?.idToken
      let parsedJwt = parseJwt(tokenJson?.token?.idToken)
      if (!this.token) return
      window.localStorage.setItem('token', tokenJson?.token?.idToken)
      window.localStorage.setItem(
        'refresh_token',
        tokenJson?.token?.refreshToken,
      )
      this.tryToSetOrg(tokenJson?.token?.idToken)
      let href = '/dash';
      const redirect = localStorage.getItem('login_redirect');
      if (redirect) {
        href = redirect;
        localStorage.removeItem('login_redirect');
      }
      window.location.href = href;
    }
  }),
  (this.getUserToken = async () => {
    try {
      auth.currentUser.getIdToken
      var idToken = await auth.currentUser.getIdToken(true)
      return idToken
    } catch (e) {

    }
  }),
  (this.renew = async () => {
    var rToken = window.localStorage.getItem('refresh_token')
    var url = await this.getUrl()
    var newToken = await refreshToken({refreshToken: rToken, url: url})

    var token = await window.localStorage.setItem('token', newToken.id_token)
    var rT = await window.localStorage.setItem('refresh_token', newToken.refresh_token)
    this.token = newToken.id_token
  }),
  (this.postToAuthn = async (accessToken) => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${accessToken}`)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify({ client: 'webapp' }),
    }
    var url = mainBaseUrl()
    var tokenResponse = await fetch(
      `${url}/company/docstar/authenticate`,
      requestOptions,
    )
    var tokenJson = await tokenResponse.json()

    this.token = tokenJson?.token?.idToken
    if (!this.token) return
    window.localStorage.setItem('token', tokenJson?.token?.idToken)
    window.localStorage.setItem(
      'refresh_token',
      tokenJson?.token?.refreshToken,
    )
    await this.tryToSetOrg(tokenJson?.token?.idToken)
    let href = '/dash';
    const redirect = localStorage.getItem('login_redirect');
    if (redirect) {
      href = redirect;
      localStorage.removeItem('login_redirect');
    }
    window.location.href = href;
  })((this.token = undefined)),
  (this.user = undefined),
  (this.getToken = async () => {
    try {
      // First check instance token
      if (this.token) {
        const isValid = await this.checkTokenValidityAndRenew();
        if (isValid) return this.token;
      }



      // Fallback to localStorage
      const storedToken = window.localStorage.getItem('token');
      if (storedToken) {
        this.token = storedToken;
        return storedToken;
      }

      return null;
    } catch (e) {
      console.error('getToken error:', e);
      return null;
    }
  }),
  (this.checkAuth = async () => {
    try {
      var url = await this.getUrl()
      var token = await this.getToken()
      let authResult = await checkAuth(token, url)
      return authResult

    } catch(e) {
      return 'unauthenticated'
    }

  }),
  (this.getOrgs = async () => {
    try {
      var token = await this.getToken()
      var parsedJwt = parseJwt(token)
      var claims = Object.keys(parsedJwt)
      var orgs = claims
        .filter(function (item) {
          return item.includes('org::')
        })
        .map(function (item) {
          return item.split('org::')[1]
        })
      this.orgs = orgs
      return orgs

    } catch(e) {
      console.log(e)
      return []
    }

  }),
  (this.authnActionIfNeeded = async () => {
    var authStatus = await this.checkAuth()
    if (authStatus != 'unauthenticated') return
    else window.location.href = 'http://localhost:8081'
  }),
  (this.org = undefined),
  (this.tryToSetOrg = (token) => {
    try {
      var parsedJwt = parseJwt(token)
      var claims = Object.keys(parsedJwt)

      var orgs = claims
        .filter(function (item) {
          return item.includes('org::')
        })
        .map(function (item) {
          return item.split('org::')[1]
        })
      this.org = orgs[0]
      window.localStorage.setItem('org', this.org)
    } catch (e) {

    }
  }),
  (this.userinfo = async () => {
    var token = await this.getToken()
    return parseJwt(token)
  }),
  (this.closeAccount = async () => {
    var token = await this.getToken()
    var url = await this.getUrl()
    var myHeaders = new Headers()
    myHeaders.append(
      'Authorization',
      `Bearer ${token}`,
    )
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }
    await fetch(`${url}/account`, requestOptions)
    //window.location.reload()
  }),
  (this.getOrg = async () => {
    if (this.org) return this.org
    return await window.localStorage.getItem('org')
  }),
  (this.getBaseUrl = async () => {
    let org = await this.getOrg()
    org = org.toLowerCase()
    var url = window.location.href
    if(url.includes("http://localhost")) return `http://localhost:3000/company/${org}`
    else return `https://hx3cymrjm2.us-east-1.awsapprunner.com/company/${org}`
  }),
  (this.getUrl = async () => {
    var url = window.location.href
    if(url.includes("http://localhost")) return `http://localhost:3000`
    else return `https://hx3cymrjm2.us-east-1.awsapprunner.com`
  }),
  (this.getGitHubAppUrl = () => {
    var url = window.location.href
    if(url.includes("http://localhost")) return `https://github.com/apps/dev-docs-github-app-dev`
    else return `https://github.com/apps/dev-docs-github-app`
  }),
  (this.setOrg = async (org) => {
    this.org = org
    window.localStorage.setItem('org', org)
    window.location.reload()
  }),
  (this.logout = async () => {
    await auth.signOut()
    await window.localStorage.removeItem('token')
    await window.localStorage.removeItem('refresh_token')
    var url = window.location.href
    if(url.includes("http://localhost")) window.location.href = 'http://localhost:8081'
    else window.location.href = 'https://dev-docs.io'
  }),
  (this.getRepos = async () => {
    const url = await this.getUrl();
    const token = await this.getToken();
    const response = await fetch(`${url}/link_github_app_user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseJson = await response.json();
    const repos = _.get(responseJson, 'data[0].repos.values', []).map(repo => repo.stringValue);
    return repos;
  }),
  (this.linkRepoToOrg = async ({
    org,
    repo,
  }) => {

    const url = await this.getUrl();
    const token = await this.getToken();
    const response = await fetch(`${url}/link_repo_to_org`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        repo,
        team: org
      })
    });
    console.log('org:', org);
    console.log('repo:', repo);
    console.log('link response status:', response.status);
  }),
  (this.isTokenExpired = (exp) => {
    if (!exp) return true;
    // Convert to milliseconds and add buffer time (e.g., 5 minutes)
    const expTime = exp * 1000 - (5 * 60 * 1000);
    return Date.now() >= expTime;
  }),

  (this.checkTokenValidityAndRenew = async () => {
    const token = window.localStorage.getItem('token') || localStorage.getItem('token')
    if (!token) return false;
    
    const decoded = parseJwt(token);
    
    let expiredToken = this.isTokenExpired(decoded.exp);
    if(expiredToken) {
      console.log('Token expired. Attempting to refresh...');
      try {
        await this.renew();
        return true;
      } catch (error) {
        console.error('Error refreshing token:', error);
        return false;
      }
    }
    return true;
  })
}
export const authInfo = new authInstance();

export default authInstance;
