<template>
  <div
    v-if="!isEditorReady"
    class="absolute top-0 left-0 z-[10000] w-full h-full p-[1.25rem] bg-[#121212] bg-opacity-75 text-[#D4D4D4] flex items-center justify-center web-editor"
  >
    <div class="flex flex-col items-center gap-4">
      <div
        class="w-12 h-12 border-4 border-t-[#D4D4D4] border-opacity-25 rounded-full animate-spin"
      ></div>
      <span>Loading...</span>
    </div>
  </div>

  <div
    class="bg-[#030711] w-screen h-screen flex flex-col pb-[58px] editor-nav"
  >
    <div
      class="w-full px-[1.25rem] py-[0.62rem] flex flex-row items-center justify-between"
      style="border-bottom: 1px solid rgba(122, 123, 128, 0.2)"
    >
      <div class="flex flex-row items-center gap-[1.25rem] text-[0.88rem]">
        <a href="/dash">
          <img class="w-[1.69rem] h-[1.63rem]" :src="logoIcon" alt="Logo" />
        </a>
        <div class="flex flex-row items-center gap-[0.31rem]">
          <img class="w-[0.75rem] h-[0.94rem]" :src="docsIcon" alt="Docs" />
          <div>Your user-facing docs</div>
          <img class="w-[1.5rem] h-[1.5rem]" :src="slashIcon" alt="Slash" />
          <img class="w-[1.25rem] h-[1.25rem]" :src="editIcon" alt="Edit" />
          <div>Edit docs</div>
        </div>
      </div>

      <div class="flex flex-row items-center gap-[15px] relative">
        <div
          v-on-click-outside="closeAITools"
          class="relative"
        >
          <button
            @click="toggleAITools"
            class="flex flex-row items-center gap-[0.31rem] hover:text-white"
          >
            <img
              class="w-[1rem] h-[1rem]"
              :src="hollowAiIcon"
              alt="ai"
            />
            <div
                class="whitespace-nowrap"
            >
                AI tools
            </div>
            <img
              class="w-[0.61rem] h-[0.32rem]"
              :src="arrowDownIcon"
              alt="down arrow"
            />
          </button>

          <div
            v-if="showAITools"
            class="absolute top-8 right-0 p-[0.94rem] flex flex-col gap-[0.31rem] rounded-[0.5rem] bg-[#1C1C1C] z-10 whitespace-nowrap"
          >
            <div
              class="text-[#A2A1A5] text-[0.75rem] px-[0.62rem]"
            >
              Edit
            </div>

            <button v-if="repoContextItems?.length > 0" class="ai-action-button flex flex-row items-center gap-[0.31rem] hover:text-white">
              <img class="w-[0.94rem] h-[0.94rem]" :src="greenAuditIcon" alt="audit" />
              <div @click="toggleAIDrawer('codeobjects')">Code Objects</div>
            </button>
            <button
              class="ai-action-button flex flex-row items-center gap-[0.31rem] hover:text-white"
            >
              <img class="w-[0.94rem] h-[0.94rem]" :src="greenAuditIcon" alt="audit" />
              <div @click="toggleAIDrawer('audit')">Audit docs</div>
            </button>

            <button
              class="ai-action-button flex flex-row items-center gap-[0.31rem] hover:text-white"
            >
              <img class="w-[0.94rem] h-[0.94rem]" :src="greenAuditIcon" alt="audit" />
              <div @click="toggleAIDrawer('analysis')">Analyze docs</div>
            </button>

            <div
              class="w-full h-[0.06rem] bg-[#7A7B80] px-[0.62rem] my-[0.31rem]"
            />

            <div
              class="text-[#A2A1A5] text-[0.75rem] px-[0.62rem]"
            >
              Write
            </div>

            <button
              class="ai-action-button flex flex-row items-center gap-[0.31rem] hover:text-white"
            >
              <img
                class="w-[0.78rem] h-[1rem]"
                :src="purpleContentIcon"
                alt="content"
              />
              <div
                @click="toggleAIDrawer('generate')"
                class="mr-[0.94rem]"
              >
                Generate content
              </div>
            </button>

            <!-- <button class="flex flex-row items-center gap-[0.31rem] hover:text-white">
              <img class="w-[1rem] h-[0.81rem]" :src="changelogIcon" alt="Changelog" />
              <div @click="toggleAIDrawer('changelog')">
                Create a changelog
              </div>
            </button> -->


          </div>
        </div>

        <Select
          style="border: none; font-size: 14px; color: #d4d4d4"
          v-model="selectedEditor"
          :options="editorOptions"
          pt:label:style="color: #D4D4D4"
          optionLabel="name"
          class="hover:text-white"
        >
          <template #dropdownicon>
            <img
              style="left: 0"
              class="w-[0.61rem] h-[0.32rem]"
              :src="arrowDownIcon"
              alt="down arrow"
            />
          </template>
        </Select>

        <!-- <button class="flex flex-row items-center gap-[0.31rem] hover:text-white">
					<div>
						Rich text editor
					</div>
					<img class="w-[0.61rem] h-[0.32rem]" :src="arrowDownIcon" alt="down arrow" />
				</button> -->

        <button
          class="ai-action-button flex flex-row items-center gap-[0.31rem] rounded-[0.5rem] border border-[#7984EB] px-[0.63rem] py-[0.44rem] hover:text-white"
          @click="toggleGithub"
        >
          <img class="w-[0.82rem] h-[0.82rem]" :src="pushIcon" alt="push" />
          <div class="ai-action-button">Push to GitHub</div>
          <img
            class="w-[0.61rem] h-[0.32rem]"
            :src="arrowDownIcon"
            alt="down arrow"
          />
        </button>
        <Popover
          append-to=".editor-nav"
          class="popover-content"
          ref="pushGitHub"
          unstyled
        >
          <div
            style="height: auto; border-radius: 0.5rem"
            class="w-[243px] h-[273px] py-[15px] bg-[#1c1c1c] rounded-lg border border-[#7a7b80]/10 flex-col justify-start items-center gap-[15px] inline-flex"
          >
            <div
              class="self-stretch h-[69px] px-[5px] flex-col justify-start items-start flex"
            >
              <div
                class="self-stretch px-2.5 justify-center items-center gap-[50px] inline-flex"
              >
                <div
                  class="grow shrink basis-0 text-[#a2a1a5] text-xs font-medium font-['Inter']"
                >
                  Push drafts to GitHub
                </div>
              </div>
              <div
                class="self-stretch px-2.5 py-[5px] justify-start items-center gap-2.5 inline-flex"
              >
                <div
                  class="h-4 p-px justify-start items-center gap-[50px] flex"
                ></div>
                <div
                  class="text-[#d3d3d3] text-sm font-semibold font-['Inter']"
                >
                  All drafts
                </div>
              </div>
              <div
                class="px-2.5 py-[5px] justify-start items-center gap-2.5 inline-flex"
              >
                <MultiSelect
                  v-if="draftOptions"
                  style="max-width: 100%; width: 100%"
                  optionLabel="label"
                  :maxSelectedLabels="0"
                  v-model="selectedDraftsToPush"
                  :options="draftOptions"
                  placeholder="Select drafts to push"
                >
                  <template #dropdownicon>
                    <i class="pi pi-map" />
                  </template>
                  <template #option="slotProps">
                    <div class="flex items-center gap-[0.5rem]">
                      <i
                        v-if="slotProps.option.action === 'delete'"
                        class="pi pi-minus"
                        style="color: red"
                      ></i>
                      <i v-else class="pi pi-plus" style="color: green"></i>
                      <span
                        style="
                          width: 200px;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        "
                        v-tooltip.left="slotProps.option.label"
                        severity="success"
                        >{{ slotProps.option.label }}</span
                      >
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <div
              class="mt-4 self-stretch h-[0px] border border-[#a2a1a5]/25"
            ></div>
            <div
              class="self-stretch h-[44px] flex-col justify-start items-start flex"
            >
              <div
                class="self-stretch h-[47px] px-[5px] flex-col justify-start items-start gap-[5px] flex"
              >
                <div
                  class="self-stretch px-2.5 justify-center items-center gap-[50px] inline-flex"
                >
                  <div
                    class="grow shrink basis-0 text-[#a2a1a5] text-xs font-medium font-['Inter']"
                  >
                    Repo
                  </div>
                </div>
                <div
                  class="self-stretch px-2.5 py-[5px] justify-start items-center gap-2.5 inline-flex"
                >
                  <div
                    class="w-4 h-4 p-px justify-center items-center gap-[50px] flex"
                  >
                    <img
                      class="w-[1.25rem] h-[1.25rem]"
                      :src="githubIcon"
                      alt="github"
                    />
                  </div>
                  <div
                    class="text-[#d3d3d3] text-sm font-normal font-['Inter']"
                  >
                    Docs
                  </div>
                </div>
              </div>
              <div
                class="mt-2 h-[10px] px-[5px] flex-col justify-center items-center gap-[5px] flex"
              >
                <div
                  class="self-stretch px-2.5 justify-center items-center gap-[50px] inline-flex"
                >
                  <div
                    class="grow shrink basis-0 text-[#a2a1a5] text-xs font-medium font-['Inter']"
                  >
                    Branch
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex flex-col items-start justify-start w-full p-2 mt-4"
            >
              <BranchPicker
                :custom-style="{ width: '100%' }"
                style="width: 100%"
                @branch-created="handleNewBranch"
                v-model="reviewBranch"
                :options="branches"
              />
            </div>
            <div class="flex flex-col items-start justify-start w-full p-2">
              <Button fluid @click="pushToGithub">
                Push change to GitHub</Button
              >
            </div>
          </div>
        </Popover>
      </div>
    </div>
    <div class="flex flex-row h-full">
      <div
        class="w-[14.89rem] bg-[#121212] p-[1.25rem] h-full overflow-y-scroll border-r-[1px] border-r-[rgba(122,123,128,0.20)]"
      >
        <div class="flex flex-col items-end justify-end w-full gap-[1.3125rem]">
          <BranchPicker
            @branch-created="handleNewBranch"
            :custom-style="{ width: '100%' }"
            style="width: 100%"
            v-model="draftBranch"
            :options="branches"
          />
          <div class="flex flex-col items-end gap-[0.31rem] w-full">
            <div class="flex justify-end w-full">
              <div
                style="justify-content: space-between"
                class="flex w-full gap-2"
              >
                <span
                  style="flex-basis: 70%"
                  class="text-[0.875rem] font-semibold"
                  >DRAFTS</span
                >
                <SearchDraftsComponent
                  v-if="drafts?.length > 0"
                  :branch="draftBranch"
                  :repo="repo"
                  :owner="owner"
                  :repoUrl="repoUrl"
                  :items="drafts"
                  title="Search your drafts"
                />
                <button @click="toggleNewFileInput" class="hover:text-white">
                  <img
                    class="w-[1.25rem] h-[1.25rem]"
                    :src="addFileIcon"
                    alt="add file"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <ul
          v-if="drafts?.length > 0 || loadedDrafts"
          class="mt-[1rem] mb-[2rem] flex flex-col gap-[0.5rem]"
        >
          <li v-if="showNewFileInput" class="new-file-input">
            <input
              v-model="newFileName"
              @keyup.enter="createNewFile"
              @keyup.esc="cancelNewFile"
              placeholder="Enter file name"
              ref="fileNameInput"
            />
          </li>
          <!-- Update the router-link section with dropdown -->
          <div
            v-for="draft in drafts"
            :key="draft"
            class="flex items-center justify-between"
          >
            <input
              v-if="renamingDraft == draft"
              v-model="newRenamedDraftName"
              @keyup.enter="renameFile({ fileName: currentDraft, branchName })"
              @keyup.esc="renamingDraft = null"
              placeholder="Enter file name"
              ref="fileNameInput"
            />
            <router-link
              v-else-if="draft.new"
              @click="removeNewTag(draft.label)"
              @contextmenu="onRightClick($event)"
              :to="{
                path: '/editor',
                query: {
                  file: draft.label,
                  branch: draftBranch,
                  repo: `${owner}%2F${repo}`,
                },
              }"
              :class="[
                'text-[0.875rem] hover:text-white cursor-pointer flex-grow p-2 ',
                route.query.file === draft ? 'active-draft' : '',
              ]"
              style="
                width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              v-tooltip.left="draft.label"
            >
            <Tag class="pr-2" severity="success" value="new"></Tag> {{ removeFileExtension(draft.label) }}
            </router-link>
            <router-link
              v-else
              @contextmenu="onRightClick($event)"
              :to="{
                path: '/editor',
                query: {
                  file: draft,
                  branch: draftBranch,
                  repo: `${owner}%2F${repo}`,
                },
              }"
              :class="[
                'text-[0.875rem] hover:text-white cursor-pointer flex-grow p-2 ',
                route.query.file === draft ? 'active-draft' : '',
              ]"
              style="
                width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              v-tooltip.left="draft"
            >
              {{ removeFileExtension(draft) }}
            </router-link>
            <Popover :ref="(el) => (contextMenuRefs[draft] = el)" unstyled>
              <div
                style="background-color: #1c1c1c"
                class="flex flex-wrap justify-center gap-4 p-2 card"
              >
                <Button
                  style="color: white"
                  @click="
                    renameDraftFile({ fileName: currentDraft, branchName })
                  "
                  text
                >
                  <i class="pi pi-pencil" />
                  Rename
                </Button>
                <Button
                  style="color: #ff7070"
                  @click="
                    deleteDraftFile({ fileName: currentDraft, branchName })
                  "
                  text
                >
                  <i class="pi pi-trash" style="color: #ff7070" />
                  Delete
                </Button>
              </div>
            </Popover>
            <div class="relative">
              <button
                @click="onRightClick($event, draft)"
                class="p-1 hover:text-white"
              >
                ⋮
              </button>
            </div>
          </div>
        </ul>
        <Skeleton
          v-else
          width="100%"
          height="10rem"
          borderRadius="16px"
        ></Skeleton>
        <PublishedDrafts
          v-if="publishedDrafts?.length > 0"
          :items="publishedDrafts"
          :repo="repo"
          :owner="owner"
          :repoUrl="repoUrl"
          :branch="draftBranch"
        />
        <Skeleton
          v-else
          width="100%"
          height="10rem"
          borderRadius="16px"
        ></Skeleton>
      </div>
      <div class="text-white bg-[#121212] w-full h-full overflow-y-scroll">
        <button
          style="z-index: 49"
          @click="toggleMetadataDrawer('metadata')"
          class="metadata-icon"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11.2 16H12.8V11.2H11.2V16ZM12 9.6C12.2267 9.6 12.4168 9.5232 12.5704 9.3696C12.724 9.216 12.8005 9.02613 12.8 8.8C12.7995 8.57387 12.7227 8.384 12.5696 8.2304C12.4165 8.0768 12.2267 8 12 8C11.7733 8 11.5835 8.0768 11.4304 8.2304C11.2773 8.384 11.2005 8.57387 11.2 8.8C11.1995 9.02613 11.2763 9.21627 11.4304 9.3704C11.5845 9.52453 11.7744 9.60107 12 9.6ZM12 20C10.8933 20 9.85333 19.7899 8.88 19.3696C7.90667 18.9493 7.06 18.3795 6.34 17.66C5.62 16.9405 5.05013 16.0939 4.6304 15.12C4.21067 14.1461 4.00053 13.1061 4 12C3.99947 10.8939 4.2096 9.85387 4.6304 8.88C5.0512 7.90613 5.62107 7.05947 6.34 6.34C7.05893 5.62053 7.9056 5.05067 8.88 4.6304C9.8544 4.21013 10.8944 4 12 4C13.1056 4 14.1456 4.21013 15.12 4.6304C16.0944 5.05067 16.9411 5.62053 17.66 6.34C18.3789 7.05947 18.9491 7.90613 19.3704 8.88C19.7917 9.85387 20.0016 10.8939 20 12C19.9984 13.1061 19.7883 14.1461 19.3696 15.12C18.9509 16.0939 18.3811 16.9405 17.66 17.66C16.9389 18.3795 16.0923 18.9496 15.12 19.3704C14.1477 19.7912 13.1077 20.0011 12 20ZM12 18.4C13.7867 18.4 15.3 17.78 16.54 16.54C17.78 15.3 18.4 13.7867 18.4 12C18.4 10.2133 17.78 8.7 16.54 7.46C15.3 6.22 13.7867 5.6 12 5.6C10.2133 5.6 8.7 6.22 7.46 7.46C6.22 8.7 5.6 10.2133 5.6 12C5.6 13.7867 6.22 15.3 7.46 16.54C8.7 17.78 10.2133 18.4 12 18.4Z"
              fill="#E6E6E6"
            />
          </svg>
        </button>
        <SelectedTextTools :editor="editor" />
        <InsertBlockTools :editor="editor" />
        <Textarea
          class="h-full"
          fluid
          v-model="rawMarkdownContent"
          v-if="selectedEditor.value == 'raw'"
          disabled
        />
        <editor-content v-else :editor="editor" class="h-full" />
      </div>
    </div>
  </div>
  <Drawer
    style="width: 62.5rem"
    v-model:visible="openAIDrawer"
    position="right"
    class="overflow-auto"
  >
    <div
      class="absolute top-0 left-0 z-50 w-full h-full p-[1.25rem] bg-[#121212] text-[#D4D4D4]"
    >
      <div
        v-if="title"
        class="text-white text-[1.25rem] font-semibold leading-[1.6875rem]"
      >
        {{ title }}
      </div>
      <div v-else>Audit docs</div>
      <Audit
        v-if="actionType == 'audit'"
        :branch="draftBranch"
        @draft="handleDraft"
      />
      <CodeObjects v-else-if="actionType == 'codeobjects' && repoContextItems?.length > 0" @draft="handleDraft" :folder-object="folderObject" :branch="draftBranch" :repo-context-items="repoContextItems" />
      <Analysis v-else-if="actionType == 'analysis'" @draft="handleDraft" :draft-branch="draftBranch" :published-branch="publishedBranch" />
      <ChangeLog v-else-if="actionType == 'changelog'" />
      <GeneratePage
        :branch="draftBranch"
        @draft="handleDraft"
        v-else-if="actionType == 'generate'"
      />
    </div>
  </Drawer>
  <Drawer
    style="width: 25.6875rem"
    v-model:visible="openMetadataDrawer"
    position="right"
  >
    <div
      v-if="title"
      class="text-white text-[1.25rem] font-semibold leading-[1.6875rem]"
    >
      {{ title }}
    </div>
    <span class="subtitle p-card-subtitle">
      View or add information about this file
    </span>
    <Metadata v-if="openMetadataDrawer" v-model="frontmatter" />
  </Drawer>
  <Toast />
  <Dialog header="Create new Draft" v-model:visible="showNewEditorDraftFile">
    <label for="fileNameInput" class="p-2 mt-4 mb-2 p-card-subtitle">You are currently are not working off a current draft.  Do you want to create a new one?</label>
    <input
      v-model="newFileName"
      placeholder="Enter file name"
      class="w-full p-2 mt-2 mb-2"
    />
    <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
      <Button @click="handleNewFileUpdate(rawMarkdownContent, frontmatter)">Create draft doc</Button>
    </div>
  </Dialog>
</template>

<script setup>
import {
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  nextTick,
  computed
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Dropdown from "primevue/dropdown";
import { vOnClickOutside } from '@vueuse/components'

import { BubbleMenu, Editor, EditorContent, FloatingMenu } from "@tiptap/vue-3";
import Code from "@tiptap/extension-code";
import Underline from "@tiptap/extension-underline";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

import InsertBlockTools from "@/components/EditorComponents/InsertBlockTools.vue";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import SelectedTextTools from "@/components/EditorComponents/SelectedTextTools.vue";
import BranchPicker from "@/components/EditorComponents/BranchPicker.vue";
import PublishedDrafts from "@/components/EditorComponents/PublishedDrafts.vue";
import SearchDraftsComponent from "@/components/EditorComponents/SearchDraftsComponent.vue";
import Metadata from "@/components/EditorComponents/Metadata.vue";
import Text from "@tiptap/extension-text";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";

import VsCodeImage from "../components/EditorExtensions/MarkdownGithubImage";
import CustomTable from "@/components/EditorExtensions/TableCard";
import ReactComponents from "@/components/EditorExtensions/ReactComponents";

import StarterKit from "@tiptap/starter-kit";
import addFileIcon from "@/assets/icon-add-file.svg";
import addFolderIcon from "@/assets/icon-add-folder.svg";
import arrowDownIcon from "@/assets/icon-arrow-down.svg";
import auditIcon from "@/assets/icon-audit.svg";
import greenAuditIcon from "@/assets/icon-audit-green.svg";
import changelogIcon from "@/assets/icon-changelog.svg";
import contentIcon from "@/assets/icon-content.svg";
import purpleContentIcon from "@/assets/icon-content-purple.svg";
import docsIcon from "@/assets/icon-docs.svg";
import editIcon from "@/assets/icon-edit.svg";
import githubIcon from "@/assets/icon-github.svg";
import branchIcon from "@/assets/icon-branch.svg";
import logoIcon from "@/assets/logo-icon.png";
import pushIcon from "@/assets/icon-push.svg";
import slashIcon from "@/assets/icon-slash.svg";
import aiIcon from "@/assets/icon-ai.svg";
import hollowAiIcon from "@/assets/icon-ai-hollow.png";

import Audit from "@/components/Audit.vue";
import GeneratePage from "@/components/GenerateDocumentationPage.vue";
import ChangeLog from "@/components/ChangeLog.vue";
import CodeObjects from "@/components/CodeObjects.vue";
import Analysis from "@/components/Analysis.vue";

import { authInfo } from "../plugins/authn.js";


import {
  getFileContent,
  getFiles,
  getOwnerAndRepo,
  updateFileContent,
  pushFileContent,
  createFileContent,
  getCustomMarkdownFiles,
  getCustomFiles,
  deleteFile,
  renameFileContent,
  getBranches,
  createNewBranch,
} from "../plugins/devdocsBackendService.js";
import {
  markdownToHtml,
  htmlToMarkdown,
} from "@/plugins/unifiedParserService.js";
import { create, debounce, get, map } from "lodash";

const router = useRouter();
const route = useRoute();
import { useToast } from "primevue/usetoast";


const toast = useToast();

const editor = ref(null);
const openAIDrawer = ref(false);
const actionType = ref(null);
const frontmatter = ref(null);
const newBranchDialog = ref(false);

const documentationRepo = ref(null)
const repoContextItems = ref([])
const updatedContextSource = ref(false)
const folderObject = ref({})

const titleData = {
  audit: "Conduct an audit",
  changelog: "Create a changelog",
  generate: "Generate content",
  metadata: "File metadata",
  codeobjects: "Code Objects",
  analysis: "Analyze Documentation"
};

const STORAGE_KEYS = {
  DRAFT_BRANCH: "draftBranch",
  REVIEW_BRANCH: "reviewBranch",
  PUBLISHED_BRANCH: "publishedBranch",
};

// Add functions to handle localStorage
const getBranchFromStorage = (key, defaultValue) => {
  return localStorage.getItem(STORAGE_KEYS[key]) || defaultValue;
};

const setBranchInStorage = (key, value) => {
  localStorage.setItem(STORAGE_KEYS[key], value);
};

// Initialize branch values
const draftBranch = ref(
  getBranchFromStorage("DRAFT_BRANCH", "dev-docs-drafts")
);
const reviewBranch = ref(
  getBranchFromStorage("REVIEW_BRANCH", "dev-docs-review")
);
const publishedBranch = ref(getBranchFromStorage("PUBLISHED_BRANCH", "main"));

const activeDropdown = ref(null);
const branches = ref([]);
const currentDraft = ref(null);
const draftOptions = ref([]);
const drafts = ref([]);
const isEditorReady = ref(true);
const loadedDrafts = ref(false);
const menu = ref();
const owner = ref(null);
const publishedDrafts = ref([]);
const publishedItems = ref({});
const pushGitHub = ref();
const repo = ref(null);
const selectedDraftsToPush = ref([]);
const title = ref(null);
const openMetadataDrawer = ref(false);

const showNewEditorDraftFile = ref(false);
const editorOptions = [
  { name: "Rich text editor", value: "rich" },
  { name: "Raw editor", value: "raw" },
];
const selectedEditor = ref(editorOptions[0]);
const rawMarkdownContent = ref("");


const showAITools = ref(false);

const toggleAITools = () => {
  showAITools.value = !showAITools.value;
};

const closeAITools = () => {
  showAITools.value = false;
};


const getRepos = async () => {
  const myHeaders = new Headers()
  try {
    const token = await authInfo.getToken()

    myHeaders.append("Content-Type", "application/json")
    if (token) {
      myHeaders.append("Authorization", `Bearer ${token}`)
    }

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    const url = await authInfo.getBaseUrl()
    const response = await fetch(`${url}/github_app_repos`, requestOptions)
    const jsonResponse = await response.json()
    return jsonResponse?.repos
  } catch (e) {
    console.error(e)
    return []
  }
}

const updateLLMTxT = async({owner, repo, branch, fileName, content}) => {
  const myHeaders = new Headers()
  try {



  } catch (e) {
    console.error(e)
    return []
  }
}

const getCodeSources = async ({owner, repo}) => {
  try {
    const repos = await getRepos()
    const newRepoContextItems = []

    let i = 0
    for (let repoItem of repos) {
      // if (repo === repoItem.name &&
      //     owner === repoItem.owner) {
      //   continue
      // }

      const files = await getCustomFiles({
        branch: repoItem.default_branch,
        owner: repoItem.owner,
        repo: repoItem.name
      })



      const repoContextItem = {
        label: repoItem.full_name,
        files: files?.files || [],
        category: repoItem.full_name
      }

      if (i === 0) {
        folderObject.value = repoContextItem
      } else {
        newRepoContextItems.push(repoContextItem)
      }
      i += 1
    }
    console.log("repoContextItems", newRepoContextItems)
    repoContextItems.value = newRepoContextItems
    updatedContextSource.value = true
  } catch (e) {
    console.error(e)
  }
}

const removeNewTag = (label) => {
  let newFiles = localStorage.getItem("newFiles");
  newFiles = newFiles ? JSON.parse(newFiles) : [];
  //const newDrafts = drafts.value.filter((draft) => draft.label !== label);
  localStorage.setItem(
    "newFiles",
    JSON.stringify(newFiles.filter((file) => file !== label))
  );
};


const toggleAIDrawer = (type) => {
  title.value = titleData[type];
  openAIDrawer.value = !openAIDrawer.value;
  actionType.value = type;
};

const toggleMetadataDrawer = () => {
  title.value = titleData["metadata"];
  openMetadataDrawer.value = !openMetadataDrawer.value;
};

//toggleMetadataDrawer

const renamingDraft = ref(null);
const newRenamedDraftName = ref(null);

const removeFileExtension = (filename) => {
  return filename ? filename.replace(/\.[^/.]+$/, "") : filename;
};

const contextMenuRefs = ref({});

const onRightClick = (event, draft) => {
  if (contextMenuRefs.value[draft]) {
    currentDraft.value = draft;
    if(draft?.label) currentDraft.value = draft.label;
    contextMenuRefs.value[draft].toggle(event);
  }
};



const toggleGithub = async (event) => {
  pushGitHub.value.toggle(event);
};

watch(
  () => route,
  async (to, from) => {
    await fetchFileFromUrl();
    await handleDraft();
  },
  { deep: true } // Need deep watching since route is an object
);

watch(draftBranch, async (newValue) => {
  let files = await getFiles({
    compareBranch: newValue,
    branch: reviewBranch.value,
  });
  let getOwnerAndRepoData = await getOwnerAndRepo();
  owner.value = getOwnerAndRepoData.owner;
  repo.value = getOwnerAndRepoData.repo;
  console.log("these are the files", files);
  drafts.value = files?.files.existing.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") );
  setBranchInStorage("DRAFT_BRANCH", newValue);
});

watch(reviewBranch, async (newValue) => {
  let files = await getFiles({
    compareBranch: draftBranch.value,
    branch: reviewBranch.value,
  });
  let getOwnerAndRepoData = await getOwnerAndRepo();
  owner.value = getOwnerAndRepoData.owner;
  repo.value = getOwnerAndRepoData.repo;
  console.log("these are the files", files);
  drafts.value = files?.files.existing.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") );;
  setBranchInStorage("REVIEW_BRANCH", newValue);
});

watch(publishedBranch, async (newValue) => {
  let getOwnerAndRepoData = await getOwnerAndRepo();
  owner.value = getOwnerAndRepoData.owner;
  repo.value = getOwnerAndRepoData.repo;
  let publishedFiles = await getCustomMarkdownFiles({
    owner: owner?.value || getOwnerAndRepoData.owner,
    repo: repo?.value || getOwnerAndRepoData.repo,
    branch: "main",
  });
  publishedDrafts.value = publishedFiles?.files;
  setBranchInStorage("PUBLISHED_BRANCH", newValue);
});

const fetchFileFromUrl = async () => {
  const { file, branch, repo } = route.query;
  if (!file) {
    return;
  }
  const owner = repo.split("%2F")[0];
  const repoName = repo.split("%2F")[1];

  const { content } = await getFileContent({
    branch,
    fileName: file,
    owner,
    repo: repoName,
  });
  rawMarkdownContent.value = content;
  let parsedContent = await markdownToHtml(content);
  editor.value.commands.setContent(parsedContent.content);
  frontmatter.value = parsedContent?.frontmatter || "";
};

const pushToGithub = async () => {
  for (let selectedDraftToPush of selectedDraftsToPush.value) {
    console.log("selectedDraftToPush", selectedDraftToPush);
    if (selectedDraftToPush.action === "delete") {
      await deleteDraftFile({
        fileName: selectedDraftToPush.label,
        branchName: reviewBranch.value,
      });
    } else {
      await pushFileContent({
        branch: draftBranch.value,
        targetBranch: reviewBranch.value,
        fileName: selectedDraftToPush.label,
        owner: owner?.value,
        repo: repo?.value,
      });
    }
  }
  toast.add({
    severity: "success",
    summary: "Success",
    detail: "changes pushed successfully",
    life: 3000,
  });
  router.replace({
      query: {}
  });
  editor.value.commands.setContent("");
};

const showNewFileInput = ref(false);
const newFileName = ref("");
const fileNameInput = ref(null);

const handleNewFileUpdate = async (markdown, frontmatterValue) => {
    let fullContent = frontmatterValue
      ? `---\n${frontmatterValue}\n---\n\n\n\n${markdown}`
      : markdown;

      const fileName = newFileName.value.endsWith(".md")
    ? newFileName.value
    : `${newFileName.value}.md`;

    await updateFileContent({
      branch: draftBranch.value,
      content: fullContent,
      fileName,
      owner: owner?.value,
      repo: repo?.value
    });
    newFileName.value = "";
    showNewEditorDraftFile.value = false;

    router.replace({
      query: {
        branch: encodeURIComponent(draftBranch.value),
        file: encodeURIComponent(filename),
        repo: encodeURIComponent(
          `${owner?.value}/${
            repo?.value
          }`
        ),
      },
    });
}

const handleNewBranch = async () => {
  let branchesData = await getBranches();
  branches.value = branchesData?.branches;
};

const checkForNewDrafts = async (existingDrafts) => {
  let newFiles = localStorage.getItem("newFiles");
  if(newFiles) {
    newFiles = JSON.parse(newFiles)
    let mappedDrafts = existingDrafts.map((draft) => {
      let includesWord = newFiles.some(function (newFile) {
        return draft.includes(newFile);
      });
      if(newFiles.includes(draft) || includesWord) {
        return {new: true, label: draft}
      } else {
        return draft
      }
    });
    // Sort the drafts array to put new files at the top
    const sortedDrafts = mappedDrafts.sort((a, b) => {
      if (a.new && !b.new) return -1;
      if (!a.new && b.new) return 1;
      return 0;
    });

    console.log("what are the new drafts", sortedDrafts);
    drafts.value = sortedDrafts;
  }
}

const getLatestDrafts = async () => {
  let files = await getFiles({
    compareBranch: draftBranch.value,
    branch: reviewBranch.value,
  });
  let existingDrafts = files?.files.existing.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") );
  let deletedDrafts = files?.files.deleted.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") )
  drafts.value = existingDrafts
  let draftOptionsDivider = [];
  for (let draft of existingDrafts) {
    draftOptionsDivider.push({ action: "create", label: draft });
  }
  for (let draft of deletedDrafts) {
    draftOptionsDivider.push({ action: "delete", label: draft });
  }
  console.log("draftOptions", draftOptionsDivider);
  draftOptions.value = draftOptionsDivider;
  await checkForNewDrafts(existingDrafts);

};

onMounted(async () => {
  let branchesData = await getBranches();
  branches.value = branchesData?.branches;
  if (!branchesData?.branches?.includes(draftBranch.value)) {
    await createNewBranch({ newBranchName: draftBranch.value });
  }
  if (!branchesData?.branches?.includes(reviewBranch.value)) {
    await createNewBranch({ newBranchName: reviewBranch.value });
  }
  let files = await getFiles({
    compareBranch: draftBranch.value,
    branch: reviewBranch.value,
  });
  let getOwnerAndRepoData = await getOwnerAndRepo();
  owner.value = getOwnerAndRepoData.owner;
  repo.value = getOwnerAndRepoData.repo;
  console.log("these are the files", files);
  drafts.value = files?.files.existing.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") );
  let draftOptionsDivider = [];
  let existingDrafts = files?.files.existing.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") );
  let deletedDrafts = files?.files.deleted.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") );
  for (let draft of existingDrafts) {
    draftOptionsDivider.push({ action: "create", label: draft });
  }
  for (let draft of deletedDrafts) {
    draftOptionsDivider.push({ action: "delete", label: draft });
  }
  console.log("draftOptions", draftOptionsDivider);
  draftOptions.value = draftOptionsDivider;
  loadedDrafts.value = true;
  let publishedFiles = await getCustomMarkdownFiles({
    owner: owner?.value || getOwnerAndRepoData.owner,
    repo: repo?.value || getOwnerAndRepoData.repo,
    branch: "main",
  });
  publishedDrafts.value = publishedFiles?.files;
  publishedItems.value = {
    label: "Files",
    icon: "pi pi-file",
  };

  const debouncedUpdate = debounce(async ({
    markdown,
    frontmatterValue,
    branch,
    fileName,
    owner,
    repo,
  }) => {
    let fullContent = frontmatterValue
      ? `---\n${frontmatterValue}\n---\n\n\n\n${markdown}`
      : markdown;
    if(!route?.query?.file) {
      showNewEditorDraftFile.value = true;
      return
    }

    await updateFileContent({
      branch,
      content: fullContent,
      fileName,
      owner,
      repo,
    });
  }, 5000);

  editor.value = new Editor({
    async onUpdate({ editor }) {
      isEditorReady.value = editor.isEditable;
      let html = await editor.getHTML();
      let markdown = await htmlToMarkdown(html);
      rawMarkdownContent.value = markdown;
      debouncedUpdate({
        markdown,
        frontmatterValue: frontmatter.value,
        branch: draftBranch.value,
        fileName: route.query.file,
        owner: owner?.value || getOwnerAndRepoData.owner,
        repo: repo?.value || getOwnerAndRepoData.repo,
      });
      // Call debounced update instead of immediate update
      // The content has changed.
    },
    extensions: [
      BubbleMenu,
      BulletList,
      Code,
      Document,
      Link,
      ListItem,
      OrderedList,
      Paragraph,
      StarterKit,
      Text,
      Underline,
      Table.configure({
      resizable: true,
      HTMLAttributes: {
        class: 'my-custom-class',
      },
    }),
      TableRow,
      TableHeader,
      TableCell,
      ReactComponents,
      VsCodeImage.configure({ allowBase64: true, inline: true })
    ],
  });
  editor.value.commands.focus()
  console.log("yeah yeah")
  await getCodeSources(getOwnerAndRepoData)
  console.log("yeah yeah")
  await fetchFileFromUrl();

  //await checkForNewDrafts(existingDrafts);

});

const handleDraft = async (data = {}) => {
  // let files = await getFiles({
  //   compareBranch: draftBranch.value,
  //   branch: reviewBranch.value,
  // });
  // drafts.value = files?.files?.existing.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") );
  await getLatestDrafts();
  openAIDrawer.value = false;
  //fetchFileFromUrl();
};

const toggleNewFileInput = () => {
  showNewFileInput.value = true;
  nextTick(() => {
    fileNameInput.value?.focus();
  });
};

const createNewFile = async () => {
  if (!newFileName.value) return;

  const fileName = newFileName.value.endsWith(".md")
    ? newFileName.value
    : `${newFileName.value}.md`;

  await createFileContent({
    branch: draftBranch.value,
    content: "",
    fileName: fileName,
    owner: owner?.value || getOwnerAndRepoData.owner,
    repo: repo?.value || getOwnerAndRepoData.repo,
  });
  showNewFileInput.value = false;
  newFileName.value = "";

  router.replace({
    query: {
      branch: encodeURIComponent(draftBranch.value),
      file: encodeURIComponent(fileName),
      repo: encodeURIComponent(
        `${owner?.value || getOwnerAndRepoData.owner}/${
          repo?.value || getOwnerAndRepoData.repo
        }` || ""
      ),
    },
  });
};


const renameFile = async ({ fileName, branchName }) => {
  if (!newRenamedDraftName.value) return;

  const newName = newRenamedDraftName.value.endsWith(".md")
    ? newRenamedDraftName.value
    : `${newRenamedDraftName.value}.md`;

  await renameFileContent({
    branch: branchName || draftBranch.value,
    fileName: fileName,
    owner: owner?.value || getOwnerAndRepoData.owner,
    repo: repo?.value || getOwnerAndRepoData.repo,
    newName,
  });
  renamingDraft.value = null;
  newRenamedDraftName.value = "";
  await getLatestDrafts();
  router.replace({
    query: {
      branch: encodeURIComponent(draftBranch.value),
      file: encodeURIComponent(newName),
      repo: encodeURIComponent(
        `${owner?.value || getOwnerAndRepoData.owner}/${
          repo?.value || getOwnerAndRepoData.repo
        }` || ""
      ),
    },
  });
};

const deleteDraftFile = async ({ fileName, branchName }) => {
  await deleteFile({
    branch: branchName || draftBranch.value,
    fileName: fileName,
    owner: owner?.value || getOwnerAndRepoData.owner,
    repo: repo?.value || getOwnerAndRepoData.repo,
  });
  await getLatestDrafts();
  // let files = await getFiles({
  //   compareBranch: draftBranch.value,
  //   branch: reviewBranch.value,
  // });
  // drafts.value = files?.files.existing.filter((file) => file.endsWith(".md") || file.endsWith(".mdx") );
  //handleDraft()
};

const renameDraftFile = async ({ fileName }) => {
  newRenamedDraftName.value = fileName;
  renamingDraft.value = fileName;
};

const cancelNewFile = () => {
  showNewFileInput.value = false;
  newFileName.value = "";
};

const repoUrl = computed(() => {
  return `https://github.com/${owner.value}/${repo.value}`;
});

const tokenExpiration = computed(() => {
  $authInstance
});





onBeforeUnmount(() => {
  editor.value?.destroy();
});
</script>

<style lang="scss" scoped>
body {
  max-width: 100vw !important;
}

// .ProseMirror-focused {
// 		outline: none;
// }

:deep(.ProseMirror) {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  min-height: 100%;
  overflow-wrap: break-word;
  padding: 4rem 8rem;
  width: 100%;
  outline: none;

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  svg {
    user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
  }

  .c-image-holder {
    user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
    border-radius: 8px;

    td,
    th {
      border: 1px solid rgb(157, 152, 152);
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      // Remove double borders between cells
      border-left: 0;
      border-top: 0;

      &:first-child {
        border-left: 1px solid rgb(157, 152, 152);
      }

      > * {
        margin-bottom: 0;
      }
    }

    // Add rounded corners to specific corners
    tr:first-child {
      th:first-child {
        border-top-left-radius: 8px;
      }
      th:last-child {
        border-top-right-radius: 8px;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }
      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    th {
      background-color: transparent;
      font-weight: bold;
      text-align: left;
      border-top: 1px solid rgb(157, 152, 152);
    }
  }

  code {
    font-family: "Fira Code", monospace;
    font-variant-ligatures: normal;
    text-shadow: none;
    background-color: #1e1e1e;
  }

  pre {
    border-radius: 1.25rem;
    border: 1px solid rgba(255, 255, 255, 0.12);
    width: 100%;
    background: rgba(17, 25, 40, 0.6);
    padding: 1rem;

    backdrop-filter: blur(10px);
  }

  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }

  ol {
    list-style-type: decimal;
    padding-left: 2rem;
  }

  a {
    color: #7984eb;
    text-decoration: underline;
    cursor: pointer;
  }
}

:deep(.ProseMirror *) {
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: white;
  margin-bottom: 1.5rem;
}

.p-multiselect-overlay {
  max-width: 200px;
}

.p-multiselect-list {
  padding: 1rem;
  justify-content: stretch;
  align-items: stretch;
}

.active-draft {
  border-radius: 0.5rem;
  background: #525151;
}

.p-multiselect-panel {
  max-width: none;
  width: auto !important;
}

.p-multiselect-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-tag-label {
  width: 100px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.p-select-dropdown {
  position: absolute;
}

.web-editor {
  .p-tooltip-text {
    background: #1c1c1c;
    color: #d3d3d3;
  }
}

.metadata-icon {
  position: absolute;
  right: 1rem;
  top: 4.25rem;
}

.ai-action-button {
  font-size: 14px;
  color: #d4d4d4;

  div {
    font-size: 14px;
    color: #d4d4d4;
  }
}

p.is-editor-empty:first-child::before {
  color: var(--gray-4);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}



/* Placeholder (on every new line) */
/* .is-empty::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  } */

/* .gradient-button:before, button:after {
	display: none !important;
} */
</style>

<!-- aireusableblock.vue enhance() updateBlock() -->
