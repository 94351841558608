<template>
    <div>
        <div v-if="listInfo">
            <div class="flex items-center justify-between mb-4">
                <span class="text-sm text-gray-400" v-if="isLoadedFromCache">
                    Using cached data
                </span>
                <div class="flex gap-2">
                    <Button
                        icon="pi pi-refresh"
                        @click="refreshData"
                        label="Run Again"
                        class="text-white p-button-sm"
                    />
                    <Button
                        icon="pi pi-trash"
                        @click="clearData"
                        label="Clear"
                        class="text-white p-button-sm"
                    />
                </div>
            </div>
            <ScrollPanel style="width: 100%; height: 100vh">
                <div>
                    <SelectButton class="mt-2 mb-2" v-model="value" :options="options" aria-labelledby="basic" />
                    <DataTable
                        :value="items"
                        v-model:selection="selectedItems"
                        v-model:filters="filters"
                        :paginator="true"
                        :rows="5"
                        filterDisplay="menu"
                        :loading="loading"
                        :globalFilterFields="['name', 'type', 'documented', 'path']"
                        currentPageReportTemplate="Displaying {first} to {last} of {totalRecords}">
                        <template #header>
                            <div class="flex items-center justify-between w-full">
                                <div>
                                    Select the items you want document
                                </div>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                            </div>
                        </template>
                        <template #paginatorstart>
                            <div style="display: flex;align-items: center;flex-direction: row; min-width: 500px">
                                <!-- <span
                                    style="max-width: 350px"
                                >
                                    Select page(s) to modify. These pages will be copied with
                                    Dev-Docs’ suggested
                                    changes and accessible in Drafts.
                                </span> -->
                                <Button
                                    @click="handleGeneration"
                                >
                                    Copy Pages to Drafts
                                </Button>
                            </div>

                        </template>
                        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                        <Column field="name" header="name" filterField="name">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" @input="filterCallback()" placeholder="Search by name" />
                            </template>
                        </Column>
                        <Column field="type" header="type" filterField="type">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" @input="filterCallback()" placeholder="Search by type" />
                            </template>
                        </Column>
                        <Column field="documented" header="documented">
                            <template #filter="{ filterModel, filterCallback }">
                                <Checkbox v-model="filterModel.value" @change="filterCallback()" :binary="true" />
                            </template>
                        </Column>
                        <Column field="path" header="path">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" @input="filterCallback()" placeholder="Search by path" />
                            </template>
                        </Column>
                    </DataTable>
                </div>


            <div class="flex flex-col gap-[0.62rem]">
                <div style="display: flex; position: relative;">
                    <Textarea
                        placeholder="Enter prompt here for what you want Dev-Docs to document on the selected items above."
                        class="w-full px-[0.63rem] py-[0.44rem] rounded-[0.5rem] bg-[#1C1C1C] min-h-[9.375rem] !border-none"
                        autoResize v-model="codeObjectPrompt" variant="filled" autofocus fluid rows="2" />
                </div>
            </div>



                <ProgressItemsBar :items="selectedItems" :processedItems="processedItems" itemType="drafts" />
            </ScrollPanel>
        </div>
        <div v-else class="flex flex-col p-fluid gap-[1.25rem] mt-[1.25rem]">
            <div class="mt-4">
                <label class="mt-4 mb-4" for="folders">Which files are these code objects typically defined in?</label>
                <ContextSelectTree v-if="repoContextItems" :data="folderObject"
                    :additional-items="repoContextItems" v-model="selectedFolders" :selected-folder-items="selectedFolders"
                    @selection-change="handleSelectionItemChange" :showGithubAppLink="false" class="mt-[0.62rem] !bg-[#1C1C1C]" />
                <Skeleton class="mt-2" v-else height="2rem" fluid />
            </div>
            <div class="mt-4">
                <label class="mt-4" for="folders">Which files are relevant as context to the code objects? This can be any code file that has relevant supporting code but might not be where the actual code object is defined.</label>
                <ContextSelectTree v-if="repoContextItems" :data="folderObject"
                    :additional-items="repoContextItems" v-model="selectedContextFolders" :selected-folder-items="selectedContextFolders"
                    @selection-change="handleContextSelectionItemChange" class="mt-[0.62rem] !bg-[#1C1C1C]" />
                <Skeleton class="mt-2" v-else height="2rem" fluid />
            </div>

            <div class="flex flex-col gap-[0.62rem]">
                <div>
                    Select the type of items you want Dev-Docs to document.
                </div>
                <div style="display: flex; position: relative;">
                    <Textarea
                        placeholder="Code objects you want documented, separated by commas"
                        class="w-full px-[0.63rem] py-[0.44rem] rounded-[0.5rem] bg-[#1C1C1C] min-h-[9.375rem] !border-none"
                        autoResize v-model="newListValue" variant="filled" autofocus fluid rows="2" />
                </div>
            </div>

            <div class="flex flex-col gap-[0.62rem]">
                <div class="">
                    <PopularPrompts
                        title="Popular code objects to document:"
                        :items="['functions', 'classes', 'methods', 'cli commands', 'vscode extension commands', 'API routes']"
                        v-model="newListValue" :appendMode="true" />
                </div>
            </div>

            <div class="flex self-end justify-end w-fit">
                <Button label="Submit" @click="listYourDocs" class="mb-8 mr-16 w-fit" />
            </div>
        </div>

        <Skeleton class="mt-2 mb-2" height="10rem" v-if="loading" fluid />
    </div>
</template>

<script>
import { FilterMatchMode } from '@primevue/core/api';
import ContextSelectTree from './ContextSelectTree.vue';
import PopularPrompts from './PopularPrompts.vue';
import ProgressItemsBar from './EditorComponents/ProgressItemsBar.vue';
import indexDbService from '../plugins/indexDbService.js';

function findParentPaths(items) {
    const paths = Array.isArray(items) ? items : [];
    return paths.filter((path, index) => {
        return !paths.some((otherPath, otherIndex) => {
            if (index === otherIndex) return false;
            return path.startsWith(otherPath + '/');
        });
    });
}



import { getFileContent, getCustomFiles, getOwnerAndRepo } from '../plugins/devdocsBackendService.js';
import { Skeleton } from 'primevue';

export default {
    props: {
        branch: {
            type: String,
        },
        repoContextItems: {
            type: Array,
            default: () => [],
        },
        folderObject: {
            type: Object,
            default: () => ({ category: "Documentation", files: [] }),
        },
    },
    data() {
        return {
            list: [],
            newListValue: '',
            selectedFolders: [],
            selectedContextFolders: [],
            currentDraftCount: null,
            processedItems: 0,
            checkCode: false,
            folderOptions: [],
            loading: false,
            listInfo: null,
            selectedItems: [],
            documentationRepo: null,
            repos: [],
            codeFiles: [],
            contextCodeFiles: [],
            reason: '',
            codeObjectPrompt: '',
            updatedContextSource: false,
            value: 'Generate Issues',
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                documented: { value: null, matchMode: FilterMatchMode.EQUALS },
                path: { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            isLoadedFromCache: false,
        };
    },
    computed: {
        progressPercentage() {
        if (!this.selectedItems.length) return 0
        return (this.processedItems / this.selectedItems.length) * 100
        }
    },
    components: {
        ContextSelectTree, PopularPrompts, ProgressItemsBar
    },
    async mounted() {
        this.documentationRepo = await getOwnerAndRepo();
        await this.checkExistingData();
        await this.loadStoredSelections();
    },
    methods: {
        async loadStoredSelections() {
            const storedSelections = await indexDbService.getTableData('codeObjectsSelections');
            if (storedSelections) {
                this.selectedFolders = storedSelections.selectedFolders;
                this.selectedContextFolders = storedSelections.selectedContextFolders;
                this.codeFiles = storedSelections.codeFiles;
                this.contextCodeFiles = storedSelections.contextCodeFiles;
            }
        },
        async checkExistingData() {
            const cachedData = await indexDbService.getTableData('codeObjects');
            console.log("what is the cached data", cachedData)
            if (cachedData) {
                this.listInfo = cachedData.listInfo;
                this.items = cachedData.items;
                this.isLoadedFromCache = true;
            }
        },
        async getCodeSources() {
                try {
                        let repos = await this.getRepos()
                        let newRepoContextItems = []
                        console.log("what is repos", repos)
                        let i = 0
                        for (let repo of repos) {
                            console.log("what is repo here", repo)
                            console.log("what is this documentation repo", this.documentationRepo)
                            if(this.documentationRepo.repo == repo.name && this.documentationRepo.owner == repo.owner){
                                continue
                            }
                            let files = await getCustomFiles({ branch: repo.default_branch, owner: repo.owner, repo: repo.name })
                            let repoContextItem = { label: repo.full_name, files: files?.files || [], category: repo.full_name }
                            if(i == 0){
                                this.folderObject = repoContextItem
                            } else {
                                newRepoContextItems.push(repoContextItem)
                            }
                            i += 1
                        }
                        this.repoContextItems = newRepoContextItems
                        this.updatedContextSource = true


                } catch (e) {
                   console.log(e)
                }

            },
        addlist() {
            let newListValue = this.newListValue.trim();
            this.list.push(newListValue);
            this.newListValue = '';
        },
        async getFolders() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var response = await fetch(`${url}/folders`, requestOptions)
                var jsonResponse = await response.json()
                this.folderOptions = jsonResponse
                this.folderObject = { category: "Documentation", files: jsonResponse }
            } catch (e) {
                this.folderOptions = []
            }
        }, handleSelectionItemChange(value) {
            // Handle the selections here
            console.log('Selected folders:', value);
            this.selectedFolders = value.selections;
            let byCategory = value.byCategory
            this.codeFiles = byCategory

            // Store selections
            indexDbService.saveTableData('codeObjectsSelections', {
                selectedFolders: this.selectedFolders,
                selectedContextFolders: this.selectedContextFolders,
                codeFiles: this.codeFiles,
                contextCodeFiles: this.contextCodeFiles
            });
        },
        handleContextSelectionItemChange(value) {
            // Handle the selections here
            console.log('Selected folders:', value);
            this.selectedContextFolders = value.selections;
            let byCategory = value.byCategory
            this.contextCodeFiles = byCategory

            // Store selections
            indexDbService.saveTableData('codeObjectsSelections', {
                selectedFolders: this.selectedFolders,
                selectedContextFolders: this.selectedContextFolders,
                codeFiles: this.codeFiles,
                contextCodeFiles: this.contextCodeFiles
            });
        },
        async getRepos() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                let url = await this.$authInstance.getBaseUrl()
                let response = await fetch(`${url}/github_app_repos`, requestOptions)
                let jsonResponse = await response.json()
                //this.repos = jsonResponse?.repos
                return jsonResponse?.repos
            } catch (e) {
                //this.folderOptions = []
            }
        },
        removeList(index) {
            console.log(index);
            this.list.splice(index, 1);
        },
        async handleGeneration() {
            this.processedItems = 0;
            this.currentDraftCount = 0;
            let newFiles = this.selectedItems.map(item => `docs/${item.name}.md`);
            const updatePromises = this.selectedItems.map(async (selected) => {
                const data = {
                    filePath: `docs/${selected.name}.md`,
                    instructions: this.codeObjectPrompt,
                    codeContext: selected.codeContext,
                    doc_owner: this.documentationRepo.owner,
                    doc_repo: this.documentationRepo.repo
                };

                const web = {
                    fileName: `docs/${selected.name}.md`,
                    codeContext: selected.codeContext,
                    reason: `${selected.name} needs to be documented. Here is some guidelines to document it: ${this.codeObjectPrompt}`,
                    action: "create",
                    ownerName: this.documentationRepo.owner,
                    repoName: this.documentationRepo.repo,
                    type: selected.type,
                    name: selected.name,
                    branchName: this.branch
                };

                const contextRepo = web.codeContext.full_repo_name;
                if (this.contextCodeFiles[contextRepo]) {
                    let processedContextFolders = this.processSelectedFolders(this.contextCodeFiles[contextRepo]);
                    web.codeContext.codefiles = [
                        ...web.codeContext.codefiles,
                        ...this.contextCodeFiles[contextRepo]
                    ];
                }

                data.web = web;
                try {
                    await this.updateYourDoc(data);
                    return true;
                } catch (error) {
                    console.error(error);
                    return false;
                }
            });

            // Process results one by one as they complete
            const results = await Promise.all(updatePromises);
            this.processedItems = results.filter(result => result).length;
            localStorage.setItem('newFiles', JSON.stringify(newFiles));
            this.$emit('draft');
        },
        async generateIssues(data) {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let { content, title } = data
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                const raw = JSON.stringify({ content, title });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/issues`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                let tableInfo = JSON.parse(result.content)
                console.log(tableInfo)
                this.listInfo = tableInfo
                this.items = tableInfo.files
            } catch (e) {
                console.log(e)
                return {}
            }

        },
        async updateYourDoc(data) {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                const raw = JSON.stringify(data);
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/code_objects/docs`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                let tableInfo = JSON.parse(result.content)
                console.log(tableInfo)
                this.listInfo = tableInfo
                this.items = tableInfo.files

                // Save to IndexDB
            } catch (e) {
                // Try to load from IndexDB if network fails
                const cachedData = await indexDbService.getTableData('codeObjects');
                if (cachedData) {
                    this.listInfo = cachedData.listInfo;
                    this.items = cachedData.items;
                }
                console.log(e)
            }
        },
        processSelectedFolders(folderObj) {
            try {
                let checkedFolders = Object.entries(folderObj)
                    .filter(([key, value]) => value.checked)
                    .map(([key]) => key);

                checkedFolders = checkedFolders.filter(folder => folder !== "0");
                checkedFolders.sort((a, b) => a.split('/').length - b.split('/').length);

                const result = [];

                for (let folder of checkedFolders) {
                    const isParent = result.some(existingFolder =>
                        existingFolder.startsWith(folder + '/'));
                    const isChild = result.some(existingFolder =>
                        folder.startsWith(existingFolder + '/'));

                    if (!isParent && !isChild) {
                        let parsedPath = folder.split('::parent::')[1]
                        result.push(parsedPath);
                    }
                }

                return result;
            } catch (e) {
                console.log(e)
                return [];
            }
        },
        async listYourDocs() {
            this.loading = true;
            let myHeaders = new Headers();
            try {
                let org = await this.$authInstance.getOrg();
                let token = await this.$authInstance.getToken();
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                this.list = this.newListValue.split(',');
                console.log("this is the selected folders", this.selectedFolders);
                let folders = this.codeFiles["Documentation"];
                folders = findParentPaths(folders);
                let codeObjects = this.codeFiles;
                delete codeObjects["Documentation"];
                const raw = JSON.stringify({ codeObjects, listOfCodeObjects: this.list });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                let url = await this.$authInstance.getBaseUrl();

                const saveResponseTwo = await fetch(`${url}/code_objects`, requestOptions);
                this.loading = false;
                let result = await saveResponseTwo.json();
                console.log(result);
                this.listInfo = result;
                this.items = result;
                this.isLoadedFromCache = false;

                // Save to IndexDB
                await indexDbService.saveTableData('codeObjects', {
                    listInfo: this.listInfo,
                    items: this.items
                });
            } catch (e) {
                // Try to load from IndexDB if network fails
                const cachedData = await indexDbService.getTableData('codeObjects');
                if (cachedData) {
                    this.listInfo = cachedData.listInfo;
                    this.items = cachedData.items;
                    this.isLoadedFromCache = true;
                }
                console.log(e);
            }
            this.loading = false;
        },
        async clearData() {
            this.listInfo = null;
            this.items = [];
            this.selectedItems = [];
            this.isLoadedFromCache = false;
            this.processedItems = 0;
            await indexDbService.clearStore();
            this.resetFormValues();
        },

        resetFormValues() {
            this.newListValue = '';
            this.codeObjectPrompt = '';
            this.selectedFolders = [];
            this.selectedContextFolders = [];
            this.codeFiles = {};
            this.contextCodeFiles = {};
            this.list = [];
        },

        async refreshData() {
            this.listInfo = null;
            console.log(this.selectedFolders)
        },
    }
};
</script>
<style>
.p-paginator {
    flex-direction: row;
}



.p-paginator-content-start {
    max-width: 350px;
}
</style>